@use '@americastestkitchen/mise/mise-styles/mise.scss';

.textWrapper {
  border-top: 2px solid mise.sourceColor(roseShade);
  padding-top: mise.spacing(x6);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (min-width: mise.breakpoint(md)) {
    flex-wrap: unset;
    max-height: 152px;
    padding-top: mise.spacing(x8);
  }
}

.textWithBadgeImage {
  max-height: 120px;
  max-width: 103px;
  min-height: 120px;
  min-width: 103px;

  order: 2;

  @media screen and (min-width: mise.breakpoint(md)) {
    max-height: unset;
    min-height: unset;
    min-height: 212px;
    min-width: 182px;
    order: unset;

    img {
      margin-top: -76px;
    }
  }
}

.textWithBadgeHeadline {
  @include mise.setFont(moret, null, null);
  color: mise.sourceColor(caviar);
  font-size: 27px;
  line-height: 32px;
  order: 1;
  max-width: 174px;
  margin-bottom: 21px;

  @media screen and (min-width: mise.breakpoint(md)) {
    font-size: 32px;
    line-height: 36.16px;
    margin-right: 0;
    min-width: 256px;
    order: unset;
  }
  @media screen and (min-width: mise.breakpoint(lg)) {
    min-width: 408px;
  }

  // Fancy underline
  em {
    --underline-intrinsic-width: 8;
    --underline-width: 12;
    --underline-color: #F8D6C7;
    --underline-cap-width: 4px;
    --underline-offset-y: -2px;
  }

  em {
    margin: 0 -5px;
    padding: 0 5px;
    display: inline;
    --underline-width-scale: calc(var(--underline-width) / var(--underline-intrinsic-width));
    background-repeat: no-repeat;
    background-image:
      linear-gradient(180deg, var(--underline-color), var(--underline-color));
    background-position-y: calc(100% - var(--underline-offset-y) * -1);
    background-size:
      calc(100% - calc(var(--underline-cap-width) * var(--underline-width-scale) * 0)) calc(var(--underline-width) * 1px),
      auto calc(var(--underline-width) * 1px),
      auto calc(var(--underline-width) * 1px);
    --underline-width: 20;
    --underline-offset-y: -2px;
    font-style: normal;
  }

  span {
    display: block;
  }
}

.textWithBadgeDek {
  @include mise.setFont(proximaNova, null, null);
  color: #2C2C2C;
  font-size: 16px;
  line-height: 22.4px;
  order: 3;

  @media screen and (min-width: mise.breakpoint(md)) {
    font-size: 24px;
    line-height: 30.9px;
    margin-right: 24px;
    order: unset;
  }
}
