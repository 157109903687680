@import '@americastestkitchen/mise/mise-styles/main.scss';

.blurred {
  height: 140px;
  position: absolute;
  background: rgba(0, 0, 0, 0.27);
  backdrop-filter: blur(5px);
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  color: $miseColorPrimaryLightTint;

  .brandIcon {
      &.ccoLogo {
      width: 250px;
    }
        &.cioLogo {
      width: 220px;
    }
  }

    @media screen and (min-width: $miseBreakpointMd) {
    height: 187px;
    align-items: center;

    .brandIcon {
      &.ccoLogo {
        width: 458px;
      }
      &.cioLogo {
        width: 327px;
      }
    }
  }
}


.image {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.card {
  display: flex;
  position: relative;
  margin-bottom: 46px;

  @media screen and (min-width: $miseBreakpointLg) {
    margin-bottom: 0;
  }
}

.button {
  position: absolute;
  bottom: -20px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;

  &__cta {
    box-shadow: 0px 2px 8px rgba(44, 44, 44, 0.5);
    color: $miseColorNeutralDarkShade;
    width: 219px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $miseColorPrimaryLightTint;
    border-radius: 60px;
  }
}