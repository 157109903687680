@use '@americastestkitchen/mise/mise-styles/mise.scss';

.upsellCards {
  display: flex;
  gap: 11px;
  margin-bottom: 16px;
  overflow-x: auto;

  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  > div {
    background-color: mise.sourceColor(rose);
    flex: 0 0 auto;
    padding: 27px 23px 23px;
    scroll-snap-align: start;
    text-align: center;
    max-width: 189px;
  }

  h3 {
    @include mise.setFont(moret, null, null);
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 7px;
  }

  p {
    @include mise.setFont(proximaNova, sm, normal);
  }
}

.upsellLinkWrap {
  display: flex;
  justify-content: center;
}

.upsellLink {
  @include mise.setFont(proximaNova, md, normal);
  color: #fff;
  display: inline-flex;
  background-color: mise.sourceColor(tomato);
  padding: 5px 24px;
  border-radius: 10em;
  font-weight: 700;
  justify-content: center;
  text-align: center;
  transition: background-color 0.2s ease-in-out;
  &:hover, &:focus {
    background-color: mise.sourceColor(tomatoShade);
  }

  &:focus {
    outline-color: mise.color(neutral,dark);
  }
}

.upsellCardIcon {
  align-items: center;
  background: #fff;
  border-radius: 50%;
  display: flex;
  height: 84px;
  justify-content: center;
  margin-bottom: 24px;
  margin-inline: auto;
  width: 85px;
  svg {
    height: 48px;
  }
}

@media screen and (min-width: mise.breakpoint(md)) {
  .upsellCards {
    gap: 17px;
    margin-bottom: 39px;
    margin-inline: auto;
    max-width: 865px;

    > div {
      flex: 1;
      padding: 33px 34px;
      width: initial;
      max-width: unset;
      height: 319px;
    }

    h3 {
      font-size: 24px;
      line-height: 26px;
      margin-bottom: 12px;
    }

    p {
      font-size: 16px;
      padding: 0 8px;
    }
  }
}
