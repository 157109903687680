@use '@americastestkitchen/mise/mise-styles/mise.scss';

$xlgBreakpoint: 1200px;

.atkBrandPage {
  color: mise.color(neutral, dark, shade);
}

// section containers

.setWidthContentBlock {
  margin: auto;
  max-width: calc(100vw - 48px);

  @media screen and (min-width: $xlgBreakpoint) {
    max-width: 1136px;
  }
}

.brandFamilySectionWrapper {
  margin: auto;

  @media screen and (min-width: mise.breakpoint(md)) {
    max-width: calc(100vw - 90px);
  }

  @media screen and (min-width: $xlgBreakpoint) {
    max-width: 1350px;
  }
}

// styled borders

.roseBorderTop {
  border-top: 2px solid #f8d6c7;
}

.roseBorderBottom {
  border-bottom: 2px solid #f8d6c7;
}

// component divs

.curiosityBrandHero {
  margin-bottom: 12px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 30px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 30px;
  }
}

.freeTrialMarketingAd {
  margin-bottom: 34px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 60px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 83px;
  }
}

.fwcbBrandsHeadline {
  margin-bottom: 24px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 40px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 60px;
  }
}

.brandImageGrid {
  margin-bottom: 46px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 40px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 38px;
  }
}

.newsletterSignupAd {
  margin-bottom: 32px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 66px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 87px;
  }
}

.awardWinningFWHeadline {
  margin-bottom: 22px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 33px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 40px;
  }
}

.atkVideoContainer {
  margin-bottom: 10px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 20px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 27px;
  }
}

.tctEpisodeContainer {
  margin-bottom: 33px;
  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 29px;
  }
  @media screen and (min-width: mise.breakpoint(lg)) {
    margin-bottom: 34px;
  }
  @media screen and (min-width: $xlgBreakpoint) {
    margin-left: 30px;
  }
}

.fullWidthImageCarouselWrapper {
  margin-bottom: 21px;
  padding-top: 24px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 30px;
    padding-top: 29px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 28px;
    padding-top: 36px;
  }
}

.tctFullWidthImage {
  margin-bottom: 26px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 23px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 34px;
  }
}

.adventureBrandHero {
  margin-bottom: 38px;
  padding-top: 25px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 51px;
    padding-top: 43px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 85px;
    padding-top: 72px;
  }
}

.exploreHeadline {
  margin-bottom: 38px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 25px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 32px;
  }
}

.tctRecipeFormula {
  margin-bottom: 28px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 25px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 50px;
  }
}

.perfectedClassicsCarouselWrapper {
  margin-bottom: 33px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 39px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 84px;
  }
}

.newFavoritesCarouselWrapper {
  margin-bottom: 40px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 85px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 75px;
  }
}

.textWithBadgeWrapper {
  margin-bottom: 28px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 38px;
    min-height: 180px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 22px;
    min-height: unset;
  }
}

.essentialEquipmentCarouselWrapper {
  margin-bottom: 28px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 42px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 92px;
  }
}

.pantryMustHaveCarouselWrapper {
  margin-bottom: 47px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 40px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 74px;
  }
}

.tctLearn {
  margin-bottom: 30px;
  padding-top: 24px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 38px;
    padding-top: 27px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    padding-top: 29px;
  }
}

.twoUpTrendingArticlesCarousel {
  margin-bottom: 44px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 40px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 72px;
  }
}

.inDepthGuidesCarousel {
  margin-bottom: 42px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 71px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 100px;
  }
}

.joinUsBrandHero {
  margin-bottom: 32px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 61px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 72px;
  }
}

.fwcbExploration {
  margin-bottom: 23px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 28px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 66px;
  }
}

.brandFamilyCardBlock {
  margin-bottom: 44px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-left: 8px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 77px;
  }
}

.tctOurApproach {
  margin-bottom: 24px;
  padding-top: 24px;

  @media screen and (min-width: mise.breakpoint(md)) {
    padding-top: 30px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 10px;
    padding-top: 32px;
  }
}

.toggleGridContainer {
  margin-bottom: 35px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 59px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 65px;
  }
}

.upsellFWContentBlock {
  margin-bottom: 24px;

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 40px;
  }
}

.upsellGridContainer {
  margin-bottom: 37px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 64px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 89px;
  }
}

.teamMemberCarouselWrapper {
  margin-bottom: 29px;

  @media screen and (min-width: mise.breakpoint(md)) {
    margin-bottom: 72px;
  }

  @media screen and (min-width: $xlgBreakpoint) {
    margin-bottom: 102px;
  }
}
