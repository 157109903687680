@use '@americastestkitchen/mise/mise-styles/mise.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: mise.breakpoint(lg)) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 24px;
    gap: 10px;
  }
}
